import React from 'react';
import PropTypes from 'prop-types';

// import { HeroForm } from 'styles/landing';

import { navigate } from 'gatsby';

// import ContactUsContainer, {
//   ContactUsModal,
// } from '../ContactUs/ContactUs.styled';
// import Styled from './SignupModal.styled'; //styles of SignupModal in contact-us.scss
import LoginWithGoogle from '../LoginWithGoogle';

const SignupModal = ({ toggleModal, product }) => {
  const emailClickHanlder = () => {
    navigate('https://web.ruttl.com');
  };

  return (
    <div className="contact-us-modal show" id="signup-modal">
      <div
        className="contact-us-container"
        style={{ maxWidth: 540, padding: 50 }}>
        <button
          type="button"
          className="button-unstyled close-button"
          onClick={toggleModal}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M368 368L144 144M368 144L144 368"
            />
          </svg>
        </button>
        <div className="signup-modal-container">
          <img
            src="/assets/img/signup-screen.svg"
            alt="signup-screen"
            width="120px"
            loading="lazy"
          />
          <h2>{`Get started with ${product || 'ruttl'}`}</h2>
          <div className="button-container">
            <LoginWithGoogle product={product} />
            <p className="or-use-text">
              <span>Or</span>
            </p>
            <button
              type="button"
              styled
              fluid
              className="button"
              onClick={emailClickHanlder}>
              Sign up with Email
            </button>
          </div>
          <div className="clients">
            <p>Trusted by world&apos;s leading brands</p>
            <div className="client-content">
              {['adobe', 'atlassian', 'sourcegraph', 'razorpay'].map((i) => (
                <div key={i} className="client-item">
                  <img
                    src={`https://web.ruttl.com/assets/img/clients/${i}.png`}
                    alt={i}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignupModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  product: PropTypes.string,
};

export default SignupModal;
