import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { Link } from 'gatsby';
// import useScrollDirection from '../../hooks/ScrollDirection';

// const targetCountries = ['PH', 'ID', 'BD', 'PK', 'IN'];
// {showBottomPopup, } : props

const productPillsData = [
  {
    title: 'Web',
    fullText: 'site Feedback',
    URL: '/website-feedback-tool/',
    icon: '/assets/img/website-feedback-icon.svg',
  },
  {
    title: 'Image/PDF',
    fullText: 'Feedback',
    URL: '/online-pdf-annotator-markup-software/',
    icon: '/assets/img/pdf-image.svg',
  },
  // {
  //   title: 'Chat',
  //   fullText: 'with Customers',
  //   URL: '/customer-feedback-tool',
  //   icon: '/assets/img/customer-feedback-icon-alt.svg',
  // },
  {
    title: 'Video',
    fullText: 'Annotation Tool',
    URL: '/video-annotation-tool/',
    icon: '/assets/img/video-review.svg',
  },
];
const Header = ({
  hideTopBar,
  productPage,
  // pricingPage,
  getStartedPage,
  // blog,
}) => {
  // const scrollDirection = useScrollDirection();

  const [isSticky, setSticky] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const [showTopbar, setShowTopbar] = useState(false);

  const mobileMenuClick = useCallback((e) => {
    if ((e.type === 'keydown' && e.key === 'Enter') || e.type === 'click')
      setMobileMenu((state) => !state);
  }, []);

  const scrollHandler = useCallback(() => {
    setSticky(window.pageYOffset > 26);
  }, []);

  const toggleDropdown = useCallback(
    (index) => (e) => {
      if (window.innerWidth < 990) {
        e.preventDefault();
      }
      setDropdown((state) => (state === index ? null : index));
    },
    [],
  );

  useEffect(() => {
    // axios.get('https://www.cloudflare.com/cdn-cgi/trace').then((val) => {
    // let location = '';

    // val.data
    //   .split('\n')
    //   .map((i) => i.split('='))
    //   .forEach((i) => {
    //     // eslint-disable-next-line prefer-destructuring
    //     if (i[0] === 'loc') location = i[1];
    //   });

    // if (targetCountries.includes(location)) {
    setShowTopbar(true);
    //   // }
    // });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  return (
    <>
      {/* {!hideTopBar && showTopbar && (
        <div
          className="slack-community"
          style={{
            backgroundColor: '#f45923',
            // backgroundColor: '#000',
          }}>
          <div className="container">
            <div className="slack-content">
              <span className="offer-icon-logo-container">
                <img
                  className="offer-icon-logo"
                  src="/assets/img/bell-icon.svg"
                  alt="Offer icon"
                  title="Offer icon"
                />
              </span>
              <p>
                <span className="insider-promo-text">
                  Get The Ultimate Guide to Website Redesign for Free
                </span>

                <Link to="/resources/website-redesign/" className="view-coupon">
                  Download E-book
                </Link>
              </p>
            </div>
          </div>
        </div>
      )} */}

      <header
        className={cn([
          {
            'product-page-header': productPage,
            header: !productPage,
            // hide: pricingPage && scrollDirection === 'down',
            // show: pricingPage && scrollDirection !== 'down',
          },
        ])}>
        <nav
          className={cn([
            'styled-nav',
            {
              isSticky,
              'nav-open': mobileMenu && showTopbar,
              'topbar-enabled': !hideTopBar,
            },
          ])}>
          <div className="header-margin-inline">
            <div className="flex align-items-center">
              <div>
                <Link to="/" className="logo-header">
                  <img
                    src="/assets/img/ruttl-logo.svg"
                    alt="ruttl logo"
                    title="ruttl logo"
                    width="78"
                    height="22"
                  />
                </Link>
              </div>
              <div className={mobileMenu ? 'menu-main menu-open' : 'menu-main'}>
                <span
                  className="hamburger"
                  aria-label="hamburger"
                  role="button"
                  tabIndex="0"
                  onClick={mobileMenuClick}
                  onKeyDown={mobileMenuClick}>
                  <svg
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 41 32"
                    style={{ enableBackground: 'new 0 0 41 32' }}>
                    <line
                      className="hamburger-line"
                      x1="1"
                      y1="1.5"
                      x2="40"
                      y2="1.5"
                    />
                    <line
                      className="hamburger-line"
                      x1="1"
                      y1="16"
                      x2="40"
                      y2="16"
                    />
                    <line
                      className="hamburger-line"
                      x1="1"
                      y1="30.5"
                      x2="40"
                      y2="30.5"
                    />
                  </svg>
                </span>

                <div className="main-nav">
                  <ul className="primary-nav">
                    {productPillsData.map((p) => (
                      <li className="hidden-mob product-pills">
                        <Link to={p.URL} className="product-pill">
                          <img
                            className={p.title.toLowerCase()}
                            src={p.icon}
                            alt="Product Logo"
                          />
                          <span>{p.title}</span>
                          <span
                            className={`full-text ${
                              p.title === 'Web' ? 'web' : ''
                            }`}>
                            &nbsp;
                            {p.fullText}
                          </span>
                        </Link>
                      </li>
                    ))}
                    {!getStartedPage && (
                      <li
                        className={`dropdown ${
                          dropdown !== 0 ? '' : 'open-nav'
                        }`}>
                        <button
                          className="no-hover-effect"
                          onClick={toggleDropdown(0)}
                          type="button">
                          Features
                        </button>

                        <div className="sub-nav">
                          <Link to="/features/comment-on-website/">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/comment-mode.svg"
                                alt="message icon designed by Brucira"
                                title="message icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">
                                Comment on Live Websites
                              </div>
                              {/* <p>
                                Leave comments and real-time edits on live
                                websites.
                              </p> */}
                            </div>
                          </Link>

                          <Link to="/features/edit-website/">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/edit-mode.svg"
                                alt="pencil icon designed by Brucira"
                                title="pencil icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">Edit Website </div>
                              {/* <p>Report issues from your mobile</p> */}
                            </div>
                          </Link>
                          <Link to="/features/review-web-applications/">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/web-apps.svg"
                                alt="monitor icon designed by Brucira"
                                title="monitor icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">
                                Review Web Application
                                {/* <span className="status-tag coming-soon">
                                  New
                                </span> */}
                              </div>
                              {/* <p>Review and collaborate on videos</p> */}
                            </div>
                          </Link>
                          <Link to="/video-annotation-tool/">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/video-review.svg"
                                alt="pdf project icon designed by Brucira"
                                title="pdf project icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">Video Annotation</div>
                              {/* <p>
                                Share feedback and leave comment on PDFs and
                                image files.
                              </p> */}
                            </div>
                          </Link>
                          <Link to="/bug-tracking-tool/">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/bug-tracking.svg"
                                alt="bug icon designed by Brucira"
                                title="bug icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">Bug Tracking</div>
                              {/* <p>
                                Chat with your website visitors in real time and
                                resolve their issues.
                              </p> */}
                            </div>
                          </Link>
                          <Link to="/online-pdf-annotator-markup-software/">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/pdf-annotation.svg"
                                alt="document icon designed by brucira"
                                title="document icon designed by brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">PDF Annotation</div>
                              {/* <p>
                                Chat with your website visitors in real time and
                                resolve their issues.
                              </p> */}
                            </div>
                          </Link>
                        </div>
                      </li>
                    )}
                    {/* 
                    {!getStartedPage && (
                      <li
                        className={`dropdown ${
                          dropdown !== 1 ? '' : 'open-nav'
                        } ${homepage ? 'hidden-web' : ''}`}>
                        <button
                          className="no-hover-effect"
                          onClick={toggleDropdown(1)}
                          type="button">
                          ruttl For
                        </button>

                        <div className="sub-nav">
                          <Link to="/for-agencies/">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/agencies.svg"
                                alt="pencil and paper icon designed by Brucira"
                                title="pencil and paper icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">Agencies</div>
                              <p>
                                Deliver projects faster by getting direct
                                feedback on all your projects
                              </p>
                            </div>
                          </Link>
                          <Link to="/for-product-companies/">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/product-companies.svg"
                                alt="pencil icon designed by Brucira"
                                title="pencil icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">Product Companies</div>
                              <p>
                                Reduce your efforts to collect your users&apos;
                                feedback
                              </p>
                            </div>
                          </Link>
                        </div>
                      </li>
                    )} */}

                    {!getStartedPage && (
                      <li>
                        <Link
                          className="no-hover-effect"
                          to="/features/integrations/">
                          Integrations
                        </Link>
                      </li>
                    )}
                  </ul>

                  <ul className="nav-right">
                    {!getStartedPage && (
                      <li>
                        <Link className="no-hover-effect" to="/pricing/">
                          Pricing
                        </Link>
                      </li>
                    )}
                    {!getStartedPage && (
                      <li>
                        <a
                          className="no-hover-effect"
                          href="https://web.ruttl.com/"
                          target="_blank"
                          rel="noopener noreferrer">
                          Login
                        </a>
                      </li>
                    )}

                    {/* {(!productPage || getStartedPage) && (
                      <li
                        className={`dropdown ${
                          dropdown !== 1 ? '' : 'open-nav'
                        }`}>
                        <button
                          className="no-hover-effect"
                          onClick={toggleDropdown(1)}
                          type="button">
                          Login
                        </button>

                        <div
                          className={`sub-nav ${
                            getStartedPage ? 'get-started' : ''
                          }`}>
                          <span style={{ marginLeft: 4 }}>Login to:</span>

                          <a
                            href="https://web.ruttl.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/website-feedback-icon.svg"
                                alt="website feedback icon designed by Brucira"
                                title="website feedback icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">Website Feedback</div>
                              <p>
                                Leave comments and real-time edits on live
                                websites.
                              </p>
                            </div>
                          </a>
                          <a
                            href="https://mobile.ruttl.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/mobile-app-feedback-icon.svg"
                                alt="mobile app feedback project icon designed by Brucira"
                                title="mobile app feedback project icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">Mobile App Feedback</div>
                              <p>Report issues from your mobile</p>
                            </div>
                          </a>
                          <a
                            href="https://web.ruttl.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/video-review.svg"
                                alt="video annotation icon designed by Brucira"
                                title="video annotation icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">
                                Video Annotation Tool
                                <span className="status-tag coming-soon">
                                  New
                                </span>
                              </div>
                              <p>Review and collaborate on videos</p>
                            </div>
                          </a>
                          <a
                            href="https://web.ruttl.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/pdf-image.svg"
                                alt="pdf project icon designed by Brucira"
                                title="pdf project icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">PDF/Image Review</div>
                              <p>
                                Share feedback and leave comment on PDFs and
                                image files.
                              </p>
                            </div>
                          </a>
                          <a
                            href="https://chat.ruttl.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <div
                              className="nav-img-wrapper"
                              style={{ backgroundColor: '#f3f3f9' }}>
                              <img
                                src="/assets/img/customer-feedback-icon.svg"
                                alt="customer feedback project icon designed by Brucira"
                                title="customer feedback project icon designed by Brucira"
                              />
                            </div>
                            <div className="nav-content">
                              <div className="nav-h4">Customer Feedback</div>
                              <p>
                                Chat with your website visitors in real time and
                                resolve their issues.
                              </p>
                            </div>
                          </a>
                        </div>
                      </li>
                    )} */}

                    {/* {(productPage || pricingPage) && (
                      <li>
                        <Link to="/offers">Offers</Link>
                      </li>
                    )} */}

                    {/* <li>
                      <button
                        type="button"
                        className="button-unstyled"
                        onClick={toggleContactUs}>
                        Contact Us
                      </button>
                    </li> */}

                    {!getStartedPage && (
                      <li className="static get-started-btn">
                        <a
                          type="button"
                          href="https://web.ruttl.com"
                          id="nav-bar-get-started"
                          className="button button-white mob-button-nav"
                          target="_blank"
                          rel="noopener noreferrer">
                          Get Started
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

Header.propTypes = {
  // showBottomPopup: PropTypes.bool,
  hideTopBar: PropTypes.bool,
  productPage: PropTypes.bool,
  // pricingPage: PropTypes.bool,
  // toggleSignup: PropTypes.func.isRequired,
  getStartedPage: PropTypes.bool,
  // blog: PropTypes.bool,
};

export default Header;
